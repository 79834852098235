<template>
	<div>
		<h4 class="h4_title">签名</h4>

		<div style="text-align: center;background-color: #FFFFFF;padding: 10px 0;">
			<van-image width="140" height="140" :src="image" style="vertical-align: middle;" @click="imageUpload()"/>
		</div>

		<div class="common_block">
			<van-button type="warning" block @click="nextStep">提交</van-button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'account_sign',
		data() {
			return {
				title: "",
				description: "",

				image: this.$axios.defaults.resourceURL + "images/img_upload.png",

				formItem: {
					token: "",
					sign_pic: "",
					lock_confirm: 1
				},
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.formItem.token = this.$route.query.token

				window.set_sign = this.set_sign
			}
		},
		methods: {
			imageUpload() {

				if (window.JsObject != null) {
					window.JsObject.signName();
				}
				
				if (window.webkit != null) {
					window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"camera": "signName"});
				}
			},

			set_sign(path) {
				this.formItem.sign_pic = path
				
				this.$axios({
					method: 'post',
					url: 'account_open/resize', 
					data: {
						img: path
					}
				}).then ((res) => {
					if (res.success) {
						this.image = res.thumb
					} else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},

			nextStep() {
				if (this.formItem.sign_pic != '') {
					this.$axios({
						method: 'post',
						url: 'account_open/alter',
						data: this.formItem
					}).then ((res) => {
						if (res.success) {
							this.$router.replace('/account/success?token=' + this.formItem.token)
						}else{
							this.$toast.fail(res.error_text)
						}
					})
				} else {
					this.$toast.fail('请点击图片手写签名')
				}
			}
		},
	}
</script>